import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Home from "./pages/Home";
import Socials from "./pages/Socials";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/socials" element={<Socials />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
