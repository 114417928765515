import React from "react";
import { Link } from "react-router-dom";

function NavigationBar() {
  return (
    <div style={{ marginBottom: "20px" }}>
      <ul className="flex justify-center mt-10 text-4xl">
        <li>
          <Link
            to="/"
            className="hover:text-gray-300 transition-colors duration-300"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/blog"
            className="hover:text-gray-300 transition-colors duration-300 ml-4"
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            to="/socials"
            className="hover:text-gray-300 transition-colors duration-300 ml-4"
          >
            Socials
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default NavigationBar;
