import SubstackFeed from "../components/SubstackFeed";
import SubstackWidget from "../components/SubstackWidget";

function Blog() {
  return (
    <div>
      <div className="w-full mx-auto px-6">
        <SubstackFeed />
      </div>
      <div>
        <div className="text-center mb-3">Subscribe to my newsletter below</div>
        <SubstackWidget />
      </div>
    </div>
  );
}

export default Blog;
