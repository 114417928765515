import Grid from "@mui/material/Unstable_Grid2";
import { AiFillLinkedin, AiOutlineMail, AiFillGithub } from "react-icons/ai";
import { BsSpotify } from "react-icons/bs";
// import "./Socials.css";

function Socials() {
  const iconStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <div
      className="container"
      style={{
        maxWidth: "50%",
        margin: "0 auto",
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={1} spacing={12}>
        <Grid item xs={12} md={6}>
          <a
            href="https://www.linkedin.com/in/kiandaneshi/"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">LinkedIn</span>
            <div style={iconStyle}>
              <AiFillLinkedin style={{ fontSize: "250px" }} />
            </div>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a
            href="https://open.spotify.com/user/kiandaneshi?si=e2fbe81e9a484065"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Spotify</span>
            <div style={iconStyle}>
              <BsSpotify style={{ fontSize: "250px" }} />
            </div>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a
            href="mailto:kian.daneshi@hotmail.com"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Email</span>
            <div style={iconStyle}>
              <AiOutlineMail style={{ fontSize: "250px" }} />
            </div>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a
            href="https://github.com/kiandaneshi"
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">GitHub</span>
            <div style={iconStyle}>
              <AiFillGithub style={{ fontSize: "250px" }} />
            </div>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default Socials;
