import React, { useEffect } from "react";
import "./SubstackWidget.css";

function SubstackWidget() {
  useEffect(() => {
    const widgetConfig = {
      substackUrl: "kiandaneshi.substack.com",
      placeholder: "example@gmail.com",
      buttonText: "Subscribe",
      theme: "custom",
      colors: {
        primary: "#000000",
        input: "#FFFFFF",
        email: "#000000",
        text: "#FFFFFF",
      },
    };

    const container = document.getElementById("custom-substack-embed");

    const script = document.createElement("script");
    script.src = "https://substackapi.com/widget.js";
    script.async = true;

    container.appendChild(script);

    window.CustomSubstackWidget = widgetConfig;
  }, []);

  return (
    <div className="center-widget">
      <div id="custom-substack-embed"></div>
    </div>
  );
}

export default SubstackWidget;
