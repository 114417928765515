import React from "react";
import Typography from "@mui/material/Typography";
import logo from "../images/logo.png";
import "./Home.css";

function Home() {
  return (
    <div className="container mx-auto text-left flex items-center pl-1">
      <div className="flex-1">
        <img src={logo} alt="My Logo" />
      </div>

      <div className="flex-1 ">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          className="text-primary text-1xl"
        >
          Hi, I'm Kian Daneshi
        </Typography>
        <Typography variant="body1" paragraph>
          As a dedicated medical student, I have a profound passion for the
          intersection of technology and healthcare. I find immense satisfaction
          in exploring the vast potential of healthtech and medtech to enhance
          the quality of life for individuals and communities. My journey in
          medicine has been shaped by a deep-rooted belief in the transformative
          power of technology to revolutionise healthcare delivery, making it
          more accessible, efficient, and patient-centric.
        </Typography>
        <Typography variant="body1" paragraph>
          Beyond my medical studies, I am particularly drawn to the world of
          data science as it relates to medicine. The ability to harness
          data-driven insights and apply them to healthcare challenges is an
          area I find both intellectually stimulating and socially impactful.
          It's not just about collecting data; it's about deriving meaningful
          conclusions and actionable recommendations to improve patient outcomes
          and the healthcare ecosystem as a whole.
        </Typography>
        <Typography variant="body1" paragraph>
          Throughout my academic journey, I have actively sought opportunities
          for collaboration with like-minded individuals and organisations.
          Whether it's collaborating on research projects, participating in
          healthtech hackathons, or engaging in interdisciplinary initiatives, I
          am always eager to join forces with those who share my passion and
          vision. In fact, I previously had the privilege of interning at
          BitFount as a Data Science Intern, where I gained hands-on experience
          in leveraging data to drive innovative solutions.
        </Typography>
      </div>
    </div>
  );
}

export default Home;
